.TestSectorTableContainer {
    display: flex;
    font-size: 22px;
    justify-content: space-between;
}

.TestSectorTableContainer table {
    border: 1px solid black;
    width: 32%;
    border-collapse: collapse;
}

.TestSectorTableContainer th {
    background-color: rgba(255, 198, 0, 0.35);
    font-weight: 600;
    padding: 0;
    border-bottom: 1px solid black;
    height: 40px;
}

.TestSectorTableContainer td {
    height: 50px;
}

.TestSectorTableContainer td:first-child {
    font-weight: 600;
    font-size: 24px;
    border-right: 1px solid black;
    text-align: center;
    width: calc(100% * (100/450));
}

.TestSectorTableContainer td:last-child {
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    width: calc(100% * (350/450));
}


.two-row-table td {
    height: 40px;
}
.one-row-table td {
    height: 80px;
}

 





.IntegratedContainer {
    padding-right: 2%;
}

.IntegratedContainer table {
    border: 1px solid black;
    width: 49%;
    border-collapse: collapse;
}