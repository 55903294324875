@import '../../../scss/common';

.ReportHeader-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 6rem;
  align-items: flex-end;

  border-style: solid;
  border-color: #EFEFEF;
  padding-bottom: 12px;
  border-width: 0 0 1px 0;

  @include mobile {
    margin-top: 3.5rem;
  }
}

.ReportHeader-logo {
  width: 6rem;
  margin-bottom: 1rem;
  cursor: pointer;

  @include mobile {
    margin-bottom: 3rem;
  }
}

.ReportHeader-logo-contanier {
  flex: 1;
}

.ReportHeader-text {
  flex: 1;
  display: flex;

  @include mobile {
    font-size: 12px;
  }
}

.start {
  justify-content: flex-start;
}

.end {
  align-items: flex-end;
  flex-direction: column;
}

.Change-Password {
  text-decoration: underline;
  font-weight: bold;
}

.Change-Password:hover {
  cursor: pointer;
  background-color: #F4F4F4;
}

.Forgot-Password-Dialog>.Textfield {
  background-color: #e7eff5;
}