

.rwTableContainer {
    display: flex;
}

.rwTableContainer table {
    width: 100%;
    border-collapse: collapse;
    border-color: black;
    border-style: solid;
    /* border-width: 1px; */
    border-width: 2px 0;
    height: min-content;
}

.rwTableContainer table:first-child {
    border-right: 3px solid black;
}
.rwTableContainer table:last-child {
    border-left: 3px solid black;
}
.rwTableContainer table:nth-child(2) {
    border-left: 3px solid black;
    border-right: 3px solid black;
    margin: 0 -3px;
}

.rwTableContainer th, .rwTableContainer td {
    font-weight: 600;
    height: 50px;
    border-right: 1px dotted black;
    text-align: center;
}
.rwTableContainer th:last-child, .rwTableContainer td:last-child {
    border-right: 0;
}

.rwTableContainer table th {
    font-size: 18px;
    background-color: rgba(225, 198, 0, 0.25);
}

.rwTableContainer table td {
    border-bottom: 1px solid black;
}

.rwTableContainer table td:nth-child(-n + 3) {
    font-size: 24px;
}
.rwTableContainer table td:nth-child(n + 4) {
    font-size: 18px;
}






.Table-labels {
    display: flex;
    text-align: center;
}

.Table-labels p {
    font-weight: 600;
    font-size: 26px;
    width: 33.33%;
    margin: 26px 0;
}

.First-labels p {
    margin-top: 0;
}

.Dotted-line {
    width: 100%;
    height: 1px;
    border-bottom: 2px dotted rgb(191, 191, 191);
    margin-top: 1.6rem;
}

// .Table-12rows {
//     height: 650px;
// }

.IntegratedTestSectorTableContainer {
    width: 66%;
    margin-top: -236px;
}

.IntegratedTestSectorTitle {
    text-align: left;
    margin-top: 48px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 24px;
}

.Display-None {
    display: none;
}

.MathrwTableContainer table td:nth-child(n + 3) {
    font-size: 16px;
}
.MathrwTableContainer table td:nth-child(-n + 2) {
    font-size: 20px;
}
.MathrwTableContainer table:last-child {
    border-right: 0;
}


.GrwTableContainer {
    display: flex;
}

.GrwTableContainer table {
    width: 100%;
    border-collapse: collapse;
    border-color: black;
    border-style: solid;
    border-width: 2px 0;
    height: min-content;
    // margin-top: 2rem;
}

.GrwTableContainer table:first-child {
    border-right: 3px solid black;
}

.GrwTableContainer th, .GrwTableContainer td {
    font-weight: 600;
    height: 50px;
    border-right: 1px dotted black;
    text-align: center;
}
.GrwTableContainer th:last-child, .GrwTableContainer td:last-child {
    border-right: 0;
}

.GrwTableContainer table th {
    font-size: 18px;
    background-color: rgba(225, 198, 0, 0.25);
}

.GrwTableContainer table td {
    border-bottom: 1px solid black;
}

.GrwTableContainer table td:nth-child(-n + 3) {
    font-size: 24px;
}
.GrwTableContainer table td:nth-child(n + 4) {
    font-size: 18px;
}