@import '../../../scss/common';

.SubjectInfo {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
}

.SubjectInfo-card {
  flex: 1;
  min-width: 30%;
  background-color: #f4f0f0a6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobile {
    min-width: 10rem;
    max-width: none;
  }
}

.SubjectCard {
    padding: 36px 36px 26px 36px;
}

.SubjectCard-title {
  font-weight: bold;
  margin-bottom: 12px;
}

.SubjectCard-content {
  color: #9A9A9A;
  font-weight: 400;
}

.SubjectCard-score {
  background-color: #FFCFCD;
  padding: 12px;
}