input,
select {
  background: #f4f8fe;
  border-radius: 16px;
  border: none;
  height: 4rem;
  margin-top: 16px;
  font-size: 1rem;
  padding-left: 16px;
  color: #323232;
}

input::placeholder,
select::placeholder {
  color: #c9ced4;
}

input:focus,
select:focus {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
