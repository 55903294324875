.SectorAverageGraph {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid black;
    border-left: 1px solid black;
}

.SectorAverageGraph th, .SectorAverageGraph td {
    border-width: 0 1px 1px 0;
    border-color: black;
    border-style: solid;
    font-weight: 600;
    height: max-content;
}

.SectorAverageGraph th {
    background-color: rgba(255, 198, 0, 0.35);
    font-size: 24px;
    width: 8%;
}

.SectorAverageGraph td {
    text-align: center;
    font-size: 18px;
    // width: 15.5%;
}

.Label-Row {
    display: flex;
}
.Label-Row p {
    width: 33.3%;
    margin: 0;
}
.Vertical-text {
    height: 80px;
}
.Vertical-text p {
    writing-mode: vertical-lr;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Chart-Row {
    text-align: center;
}

.Sector-details {
    font-weight: 600;
    font-size: 22px;
    margin-top: 0.5rem;
    text-align: left;
}


@media (max-width: 1300px) {
    .percentage-symbol {
        display: none;
    }
}

