@import '../../../scss/common';

.Graph-container {
  margin-top: 1rem;
  height: 30vw;

  @include mobile {
    height: 22rem;
    font-size: 0.65rem;
  }
}


.NewAverageGraphTable {
  width: 100%;
  border-collapse: collapse;
  border-color: black;
  border-style: solid;
  border-width: 1px 0;
  height: min-content;
}

.NewAverageGraphTable th, .NewAverageGraphTable td {
  height: 60px;
  font-weight: 600;
  text-align: center;
}

.NewAverageGraphTable th {
  background-color: rgba(255, 212, 64, 0.35);
  font-size: 24px;
  border-bottom: 1px solid black;
}

.NewAverageGraphTable td {
  font-size: 22px;
}

.NewAverageGraphTable td:first-child, .NewAverageGraphTable td:last-child {
  width: 25%;
}
.NewAverageGraphTable td:last-child {
  border-left: 1px solid black;
}

.NewAverageGraphTable th:nth-child(-n + 2), .NewAverageGraphTable td:nth-child(1) {
  border-right: 1px solid black;
}


.Desc-Container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 5px;
  text-align: left;
  font-weight: 600;
  font-size: 20px;
}

.Display-None {
  display: none;
}

.Add-desc {
  font-weight: 600;
  font-size: 18px;
}