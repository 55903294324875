@import '../../../scss/common';

.ScoreTable-row>td, .ScoreTable-header>td {
  width: 20%;
  text-align: center;
  font-weight: 500;
  margin: 0;
  @include mobile {
    font-size: 12px;
  }
}

.ScoreTable-row, .ScoreTable-header, .ScoreTable-total { 
  margin: 0;
  padding: 0;
  height: 3.5rem;
}

.ScoreTable-header>td {
  font-weight: 700;
}

.ScoreTable-header {
  border-style: solid;
  border-color: #EFEFEF;
  border-width: 0 0 1px 0;
}

.ScoreTable-row:nth-child(even) {
  background : #f4f0f07c;
}

.ScoreTable-row:nth-child(odd) {
  background : #ffffff7c;
}

.ScoreTable {
  margin-top: 1rem;
  width: 100%;
  border-collapse: collapse;
}

.ScoreTable-total {
  background-color: #FFCFCD;
  border-style: solid;
  border-color: #B1302A;
  border-width: 1px 0 1px 0;
}

#Total-title {
  background-color: #B1302A;
  color: #ffffff;
}