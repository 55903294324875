@import "../../scss/common";

.Login-page {
  height: 100vh;
  height: var(--vh);

  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }
}

.Login-image {
  width: 24vw;
  height: auto;

  @include mobile {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
}

.Login-container-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    flex: 0;
  }
}

.Login-container-input {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    flex: 0;
  }
}

.LoginInput-container {
  display: flex;
  flex-direction: column;
  width: 28rem;

  @include mobile {
    width: 90%;
  }
}

.Select-container {
  width: 100%;
  display: flex;
}

#Welcome-text {
  font-weight: $bold;
  font-size: 1.75rem;
  @include mobile {
    display: none;
  }
}

#Login-text {
  font-size: 1.25rem;
  margin-top: 1.2rem;
}

.Forgot-password {
  width: fit-content;
  float: right;
  margin-top: 8px;
  margin-bottom: 16px;
  color: #d2d2d2;
  text-align: right;
  text-decoration: underline;
}

.Forgot-password:hover {
  color: #858585;
  cursor: pointer;
}

.Forgot-Password-Dialog {
  background-color: #f4f8fe;
  display: flex;
  flex-direction: column;
  margin: 16px;
}

.Forgot-Password-Dialog-title {
  font-weight: bold;
  text-align: center;
}

.Forgot-Password-Dialog-content {
  color: #6c6c6c;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 16px;
}
