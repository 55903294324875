@import '../../../scss/common';

.SummaryTable-row>td {
  width: 20%;
  text-align: center;
  font-weight: 500;
  margin: 0;
  padding: 12px;
  font-size: 15px;
  @include mobile {
    font-size: 12px;
  }
}

.SummaryTable-row-6>td {
  width: 100% / 6;
  text-align: center;
  font-weight: 500;
  margin: 0;
  padding: 12px;
  font-size: 15px;
  @include mobile {
    font-size: 12px;
  }
}

.SummaryTable-row, .SummaryTable-row-6:nth-child(even) {
  background : #f4f0f07c;
}

.SummaryTable-row, .SummaryTable-row-6:nth-child(odd) {
  background : #ffffff7c;
}


.SummaryTable-row, .SummaryTable-row-6 { 
  margin: 0;
  padding: 0;
  height: 3.5rem;
}

.SummaryTable {
  width: 100%;
  border-spacing: 0;
}

.SummaryTable-header>td {
  font-weight: 700;
}





.New-SummaryTable {
  width: 100%;
  border-width: 3px 0;
  border-style: solid;
  border-color: black;
  border-collapse: collapse;
}

.New-SummaryTable tbody {
  // display: flex;
  width: 100%;
  background-color: rgba(255, 212, 64, 0.45);
}

.New-SummaryTable tr {
  height: 80px;
  width: 100%;
  display: flex; 
}

.New-SummaryTable td {
  width: 25%;
  border-right: 1px dotted black;
  display: flex;
  justify-content: space-evenly;
}

.New-SummaryTable td:last-child {
  border-right: 0;
}

.New-SummaryTable td div {
  margin: auto 0;
  text-align: center;
}
.New-SummaryTable div:first-child {
  font-size: 24px;
  font-weight: 200;
}
.New-SummaryTable div:last-child {
  font-size: 32px;
  font-weight: 900;
}



.New-SummaryTable-Desc {
  width: 100%;
}

.New-SummaryTable-Desc tr {
  width: 100%;
  display: flex; 
}

.New-SummaryTable-Desc td {
  width: 25%;
  display: flex;
  justify-content: space-evenly;
  font-size: 18px;
}



.New-SummaryTable-Desc {
  padding-top: 10px;
  margin-bottom: -20px;
}

.New-SummaryTable-Desc .Unhovered-Desc {
  opacity: 0;
}

.New-SummaryTable-Desc .Hovered-Desc {
  opacity: 1;
  font-size: 18px;
  background-color: rgba(255, 212, 64, 0.1);
  border-radius: 6px;
}

.info-container {
  display: flex;
  justify-content: space-between;
}

.Score-div {
  font-size: 22px;
  font-weight: 600;
  width: 25%;
  text-align: center;
  // margin-left: calc(75% + 5px);
  // background-color: yellow;
  // flex-basis: 25%;
  min-width: 25%;
}

.National-percentage {
  font-size: 22px;
  font-weight: 600;
  width: 25%;
  text-align: left;
  // margin-left: calc(75% + 5px);
  // flex-basis: 25%;
  min-width: 25%;
}

.Display-None {
  display: none;
}

.Math-Summary-Table td:nth-of-type(4) { display: none; }
.Math-Summary-Table td {
  width: 33.33%;
  border-right: 1px dotted black;
  display: flex;
  justify-content: space-evenly;
}
.Math-Summary-Table td:nth-last-child(2) {
  border-right: 0;
}
.Math-SummaryTable-Desc td {
  width: 33.33%;
}