@import '../../../scss/common';

.ReportHeader-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 6rem;
  align-items: flex-end;

  border-style: solid;
  border-color: #EFEFEF;
  padding-bottom: 12px;
  border-width: 0 0 1px 0;

  @include mobile {
    margin-top: 3.5rem;
  }
}

.ReportHeader-logo {
  width: 6rem;
  margin-bottom: 1rem;
  cursor: pointer;

  @include mobile {
    margin-bottom: 3rem;
  }
}

.ReportHeader-logo-contanier {
  flex: 1;
}

.ReportHeader-text {
  flex: 1;
  display: flex;

  @include mobile {
    font-size: 12px;
  }
}

.start {
  justify-content: flex-start;
}

.end {
  align-items: flex-end;
  flex-direction: column;
}

.Change-Password {
  text-decoration: underline;
  font-weight: bold;
}

.Change-Password:hover {
  cursor: pointer;
  background-color: #F4F4F4;
}

.Forgot-Password-Dialog>.Textfield {
  background-color: #e7eff5;
}


.New-ReportHeader-Container {
  width: 100%;
  // margin-top: 2rem;
 
}

.New-ReportHeader-Logo-contanier {
  width: 13rem;
  margin: 0 0 0 -4rem;
}

.New-ReportHeader-Logo {
  width: 100%;
  cursor: pointer;
  
}


.Stu-info-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3rem;
  margin-left: 5px;
  text-align: left;
}

.Stu-info {
  font-weight: 800;
  font-size: 24px;
}


.Quarter-subject {
  display: inline;
  font-weight: 600;
  font-size: 24px;

}

.Grade-type, .Grade-type span {
  font-weight: 800;
  font-size: 42px;
}


.New-Change-Password {
  font-size: 1rem;
  color: gray;
  text-decoration: none;
}

.New-Change-Password:hover {
  cursor: pointer;
  color: black;
  text-decoration: underline;
}


@media(min-width: 1600px) {
  .New-ReportHeader-Logo-contanier {
    // margin-top: 0;
    width: calc(210px + 7%);
  }
}

.Display-None {
  display: none;
}