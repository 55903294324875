@import "../../../scss/common";

.AverageTable-row > td,
.AverageTable-header > td {
  width: calc(100% / 8);
  text-align: center;
  font-weight: 500;
  margin: 0;
  @include mobile {
    font-size: 12px;
  }
}

.AverageTable-row,
.AverageTable-header,
.AverageTable-total {
  margin: 0;
  padding: 0;
  height: 3.5rem;
}

.AverageTable-row-cursor {
  cursor: pointer;
}

.AverageTable-header > td {
  font-weight: bold;
}

.AverageTable-header {
  border-style: solid;
  border-color: #efefef;
  border-width: 0 0 1px 0;
}

.AverageTable-row:nth-child(even) {
  background: #f4f0f07c;
}

.AverageTable-row:nth-child(odd) {
  background: #ffffff7c;
}

.AverageTable {
  margin-top: 1rem;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  border-collapse: collapse;
}

.AverageTable-total {
  background-color: #ffcfcd;
  border-style: solid;
  border-color: #b1302a;
  border-width: 1px 0 1px 0;
}

#Total-title {
  background-color: #b1302a;
  color: #ffffff;
}

.AverageTable-title {
  font-weight: bold;
  text-align: start;
  margin-top: 4rem;
}
