.PrevReportTable {
    width: 100%;
    border-collapse: collapse;
    border-color: black;
    border-style: solid;
    border-width: 1px 0 0 0;
    display: flex;
    flex-wrap: wrap;
}

.PrevReportTable .PrevReportTableCell {
    // height: 60px;
    width: calc(50% - 2px);
    font-weight: 600;
    text-align: center;
    font-size: 20px;
    border-collapse: collapse;
    border-color: black;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    padding: 19px 0;
    // background-color: transparent;
    // border-radius: 0;
    // display: block;
}

.PrevReportTable .PrevTestHover:hover {
    background-color: rgba(255, 212, 64, 0.35);
    cursor: pointer;
    // border-width: 0 1px 1px 1px;
    // border-color: black;
    // margin: 0 -2px 0px -2px;
}

.PrevReportTable .PrevReportTableCell.SelectedTestCell {
    background-color: rgba(255, 212, 64, 0.35);
    // border-width: 0 1px 1px 1px;;
    // border-color: black;
    // margin: 0 -2px 0px -2px;
}