.Select-component {
  text-indent: 5px;
  flex: 1;
  padding-left: 8px;
  padding-right: 5px;
}

.Select-component:first-child {
  margin-right: 0.75rem;
}

.Select-component:last-child {
  margin-left: 0.75rem;
}