@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');
@import './ui/scss/common';

::-moz-selection {
  background: #B1302A;
  color: #fff;
}
::selection {
  background: #B1302A;
  color: #fff;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR';
  font-weight: $medium;
}

span {
  text-align: start;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow:auto;
  max-width: 100%;
  // overflow-x: hidden;

  background: white;
  background-image: radial-gradient(#e1e8f0 1px, transparent 0);
  background-size: 20px 20px;
  background-position: -19px -19px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
