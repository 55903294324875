.Button {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
}

button {
  min-height: 4rem;
  background: #B02F29;
  border-radius: 16px;
  border: none;
  padding-left: 16px;
  flex: 1;
  transition: background-color 150ms;
  transition-timing-function: ease-in-out;
}

button:focus { 
  outline: none; 
}

button:hover {
  background-color: #F07872;
  cursor: pointer;
}

.Button-text {
  font-size: 1rem;
  color: #ffffff;
}

.Button-text-problem {
  font-size: 1rem;
  color: #B02F29;
  margin-bottom: 12px;
}