@import '../../scss/common';

.ReportPage {
  display: flex;
  justify-content: center;
  margin-bottom: 12rem;
}

.ReportPage-container {
  width: 60%;
  min-width: 55rem;

  @include mobile {
    width: 95%;
    min-width: 95%;
  }
}

.NewReportPage {
  display: flex;
  justify-content: center;
  // margin-bottom: 12rem;
  background-image: 
  url('../../resource/yellow_background.jpeg'),
  url('../../resource/yellow_background_bottom.jpeg'),
  url('../../resource/yellow_background_bottom.jpeg'),
  url('../../resource/yellow_background_bottom.jpeg'),
  url('../../resource/yellow_background_bottom.jpeg'),
  url('../../resource/yellow_background_bottom.jpeg');
  background-size: 100% auto, 100% auto;
  background-position: top center, top 20% center, top 40% center, top 60% center, top 80% center, bottom center;
  background-repeat: no-repeat;
  padding-bottom: 12rem;
  min-width: 1080px;
  width: 100%;
  
}

.NewReportPage-container {
  width: 84%;
  // margin-left: max(10%, 90px);
  // margin-right: max(5%, 30px);
  margin-left: 10%;
  margin-right: 5%;
  overflow-x: auto; 
  overflow-y: auto; 
  overflow: visible;
}

.break-before {
  break-before: page;
}

.display-none {
  display: none;
}