// Breakpoints
$breakpoint-mobile: 900px;

$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;

@mixin mobile {
    @media (max-width: #{$breakpoint-mobile}){
        @content;
    }
}