.TitleHeader, .TitleHeader-title, .TitleHeader-comment {
  width: 100%;
  text-align: start;
}

.TitleHeader {
  margin-top: 3rem;
}

.TitleHeader-title {
  font-weight: 700;
  color: #000000;
}

.TitleHeader-comment {
  font-weight: 400;
  font-size: 0.75rem;
  color: #9A9A9A;
}

.New-TitleHeader {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.New-TitleHeader-title {
  font-weight: 600;
  font-size: 24px;
}

.New-TitleHeader-comment {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin-top: -1rem;
}