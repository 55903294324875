@import "../../scss/common";

.TeacherStudentsPage {
  display: flex;
  justify-content: center;
  margin-bottom: 12rem;
  .TeacherStudentsWrap {
    width: 60%;
    min-width: 55rem;

    @include mobile {
      width: 95%;
      min-width: 95%;
    }
    .StudentWrap {
      display: flex;
      flex-direction: column;
      align-items: center;

      .Title {
        display: flex;
        border-bottom: 1px solid #dddddd;
        .StuNum {
          width: 100px;
        }
        .StuName {
          width: 100px;
        }
        .StuClassNo {
          width: 100px;
        }
      }
      .StudentInner {
        display: flex;
        border-bottom: 1px solid #dddddd;
        .StuNum {
          width: 100px;
        }
        .StuName {
          width: 100px;
        }
        .StuClassNo {
          width: 100px;
        }
      }
    }
  }
}
