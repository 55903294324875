.PrevExamTable {
    width: 100%;
    border-collapse: collapse;
    border-color: black;
    border-style: solid;
    border-width: 1px 0;
    // height: min-content;
}

.PrevExamTable th, .PrevExamTable td {
    height: 60px;
    font-weight: 600;
    text-align: center;
}

.PrevExamTable th {
    background-color: rgba(255, 212, 64, 0.35);
    font-size: 24px;
    border-bottom: 1px solid black;
}

.PrevExamTable td {
    font-size: 22px;
    width: 25%;
}

.PrevExamTable th:nth-child(2), .PrevExamTable td:nth-child(2) {
    width: 50%;
}
.PrevExamTable th:nth-child(1), .PrevExamTable td:nth-child(1) {
    border-right: 1px solid black;
}
.PrevExamTable th:nth-child(3), .PrevExamTable td:nth-child(3) {
    border-left: 1px solid black;
}

.IntegratedPrevExamTable td:nth-last-child(-n+6) {
    border-top: 1px solid black;
}

.display-none {
    display: none;
}